// source: qis/product/v1/product.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var qis_regional_v1_regional_pb = require('../../../qis/regional/v1/regional_pb.js');
goog.object.extend(proto, qis_regional_v1_regional_pb);
var qis_transaction_v1_crypto_pb = require('../../../qis/transaction/v1/crypto_pb.js');
goog.object.extend(proto, qis_transaction_v1_crypto_pb);
goog.exportSymbol('proto.qis.product.v1.CryptoFiatSwap', null, global);
goog.exportSymbol('proto.qis.product.v1.CryptoMintNFT', null, global);
goog.exportSymbol('proto.qis.product.v1.CryptoSwap', null, global);
goog.exportSymbol('proto.qis.product.v1.FiatCrytoSwap', null, global);
goog.exportSymbol('proto.qis.product.v1.FiatMintNFT', null, global);
goog.exportSymbol('proto.qis.product.v1.NFT', null, global);
goog.exportSymbol('proto.qis.product.v1.Product', null, global);
goog.exportSymbol('proto.qis.product.v1.Product.ProductTypeCase', null, global);
goog.exportSymbol('proto.qis.product.v1.Side', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.product.v1.Product = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.qis.product.v1.Product.oneofGroups_);
};
goog.inherits(proto.qis.product.v1.Product, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.product.v1.Product.displayName = 'proto.qis.product.v1.Product';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.product.v1.CryptoSwap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.product.v1.CryptoSwap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.product.v1.CryptoSwap.displayName = 'proto.qis.product.v1.CryptoSwap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.product.v1.CryptoFiatSwap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.product.v1.CryptoFiatSwap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.product.v1.CryptoFiatSwap.displayName = 'proto.qis.product.v1.CryptoFiatSwap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.product.v1.FiatCrytoSwap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.product.v1.FiatCrytoSwap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.product.v1.FiatCrytoSwap.displayName = 'proto.qis.product.v1.FiatCrytoSwap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.product.v1.FiatMintNFT = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.product.v1.FiatMintNFT, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.product.v1.FiatMintNFT.displayName = 'proto.qis.product.v1.FiatMintNFT';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.product.v1.CryptoMintNFT = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.product.v1.CryptoMintNFT, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.product.v1.CryptoMintNFT.displayName = 'proto.qis.product.v1.CryptoMintNFT';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.product.v1.NFT = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.product.v1.NFT, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.product.v1.NFT.displayName = 'proto.qis.product.v1.NFT';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.qis.product.v1.Product.oneofGroups_ = [[21,22,23,24,25]];

/**
 * @enum {number}
 */
proto.qis.product.v1.Product.ProductTypeCase = {
  PRODUCT_TYPE_NOT_SET: 0,
  CRYPTO_SWAP: 21,
  CRYPTO_FIAT_SWAP: 22,
  FIAT_CRYPTO_SWAP: 23,
  FIAT_MINT_NFT: 24,
  CRYPTO_MINT_NFT: 25
};

/**
 * @return {proto.qis.product.v1.Product.ProductTypeCase}
 */
proto.qis.product.v1.Product.prototype.getProductTypeCase = function() {
  return /** @type {proto.qis.product.v1.Product.ProductTypeCase} */(jspb.Message.computeOneofCase(this, proto.qis.product.v1.Product.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.product.v1.Product.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.product.v1.Product.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.product.v1.Product} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.product.v1.Product.toObject = function(includeInstance, msg) {
  var f, obj = {
    cryptoSwap: (f = msg.getCryptoSwap()) && proto.qis.product.v1.CryptoSwap.toObject(includeInstance, f),
    cryptoFiatSwap: (f = msg.getCryptoFiatSwap()) && proto.qis.product.v1.CryptoFiatSwap.toObject(includeInstance, f),
    fiatCryptoSwap: (f = msg.getFiatCryptoSwap()) && proto.qis.product.v1.FiatCrytoSwap.toObject(includeInstance, f),
    fiatMintNft: (f = msg.getFiatMintNft()) && proto.qis.product.v1.FiatMintNFT.toObject(includeInstance, f),
    cryptoMintNft: (f = msg.getCryptoMintNft()) && proto.qis.product.v1.CryptoMintNFT.toObject(includeInstance, f),
    side: jspb.Message.getFieldWithDefault(msg, 10, 0),
    slippage: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    rebate: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.product.v1.Product}
 */
proto.qis.product.v1.Product.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.product.v1.Product;
  return proto.qis.product.v1.Product.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.product.v1.Product} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.product.v1.Product}
 */
proto.qis.product.v1.Product.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 21:
      var value = new proto.qis.product.v1.CryptoSwap;
      reader.readMessage(value,proto.qis.product.v1.CryptoSwap.deserializeBinaryFromReader);
      msg.setCryptoSwap(value);
      break;
    case 22:
      var value = new proto.qis.product.v1.CryptoFiatSwap;
      reader.readMessage(value,proto.qis.product.v1.CryptoFiatSwap.deserializeBinaryFromReader);
      msg.setCryptoFiatSwap(value);
      break;
    case 23:
      var value = new proto.qis.product.v1.FiatCrytoSwap;
      reader.readMessage(value,proto.qis.product.v1.FiatCrytoSwap.deserializeBinaryFromReader);
      msg.setFiatCryptoSwap(value);
      break;
    case 24:
      var value = new proto.qis.product.v1.FiatMintNFT;
      reader.readMessage(value,proto.qis.product.v1.FiatMintNFT.deserializeBinaryFromReader);
      msg.setFiatMintNft(value);
      break;
    case 25:
      var value = new proto.qis.product.v1.CryptoMintNFT;
      reader.readMessage(value,proto.qis.product.v1.CryptoMintNFT.deserializeBinaryFromReader);
      msg.setCryptoMintNft(value);
      break;
    case 10:
      var value = /** @type {!proto.qis.product.v1.Side} */ (reader.readEnum());
      msg.setSide(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSlippage(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRebate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.product.v1.Product.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.product.v1.Product.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.product.v1.Product} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.product.v1.Product.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCryptoSwap();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.qis.product.v1.CryptoSwap.serializeBinaryToWriter
    );
  }
  f = message.getCryptoFiatSwap();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.qis.product.v1.CryptoFiatSwap.serializeBinaryToWriter
    );
  }
  f = message.getFiatCryptoSwap();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.qis.product.v1.FiatCrytoSwap.serializeBinaryToWriter
    );
  }
  f = message.getFiatMintNft();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.qis.product.v1.FiatMintNFT.serializeBinaryToWriter
    );
  }
  f = message.getCryptoMintNft();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.qis.product.v1.CryptoMintNFT.serializeBinaryToWriter
    );
  }
  f = message.getSide();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getSlippage();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getRebate();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
};


/**
 * optional CryptoSwap crypto_swap = 21;
 * @return {?proto.qis.product.v1.CryptoSwap}
 */
proto.qis.product.v1.Product.prototype.getCryptoSwap = function() {
  return /** @type{?proto.qis.product.v1.CryptoSwap} */ (
    jspb.Message.getWrapperField(this, proto.qis.product.v1.CryptoSwap, 21));
};


/**
 * @param {?proto.qis.product.v1.CryptoSwap|undefined} value
 * @return {!proto.qis.product.v1.Product} returns this
*/
proto.qis.product.v1.Product.prototype.setCryptoSwap = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.qis.product.v1.Product.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.product.v1.Product} returns this
 */
proto.qis.product.v1.Product.prototype.clearCryptoSwap = function() {
  return this.setCryptoSwap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.product.v1.Product.prototype.hasCryptoSwap = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional CryptoFiatSwap crypto_fiat_swap = 22;
 * @return {?proto.qis.product.v1.CryptoFiatSwap}
 */
proto.qis.product.v1.Product.prototype.getCryptoFiatSwap = function() {
  return /** @type{?proto.qis.product.v1.CryptoFiatSwap} */ (
    jspb.Message.getWrapperField(this, proto.qis.product.v1.CryptoFiatSwap, 22));
};


/**
 * @param {?proto.qis.product.v1.CryptoFiatSwap|undefined} value
 * @return {!proto.qis.product.v1.Product} returns this
*/
proto.qis.product.v1.Product.prototype.setCryptoFiatSwap = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.qis.product.v1.Product.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.product.v1.Product} returns this
 */
proto.qis.product.v1.Product.prototype.clearCryptoFiatSwap = function() {
  return this.setCryptoFiatSwap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.product.v1.Product.prototype.hasCryptoFiatSwap = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional FiatCrytoSwap fiat_crypto_swap = 23;
 * @return {?proto.qis.product.v1.FiatCrytoSwap}
 */
proto.qis.product.v1.Product.prototype.getFiatCryptoSwap = function() {
  return /** @type{?proto.qis.product.v1.FiatCrytoSwap} */ (
    jspb.Message.getWrapperField(this, proto.qis.product.v1.FiatCrytoSwap, 23));
};


/**
 * @param {?proto.qis.product.v1.FiatCrytoSwap|undefined} value
 * @return {!proto.qis.product.v1.Product} returns this
*/
proto.qis.product.v1.Product.prototype.setFiatCryptoSwap = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.qis.product.v1.Product.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.product.v1.Product} returns this
 */
proto.qis.product.v1.Product.prototype.clearFiatCryptoSwap = function() {
  return this.setFiatCryptoSwap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.product.v1.Product.prototype.hasFiatCryptoSwap = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional FiatMintNFT fiat_mint_nft = 24;
 * @return {?proto.qis.product.v1.FiatMintNFT}
 */
proto.qis.product.v1.Product.prototype.getFiatMintNft = function() {
  return /** @type{?proto.qis.product.v1.FiatMintNFT} */ (
    jspb.Message.getWrapperField(this, proto.qis.product.v1.FiatMintNFT, 24));
};


/**
 * @param {?proto.qis.product.v1.FiatMintNFT|undefined} value
 * @return {!proto.qis.product.v1.Product} returns this
*/
proto.qis.product.v1.Product.prototype.setFiatMintNft = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.qis.product.v1.Product.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.product.v1.Product} returns this
 */
proto.qis.product.v1.Product.prototype.clearFiatMintNft = function() {
  return this.setFiatMintNft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.product.v1.Product.prototype.hasFiatMintNft = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional CryptoMintNFT crypto_mint_nft = 25;
 * @return {?proto.qis.product.v1.CryptoMintNFT}
 */
proto.qis.product.v1.Product.prototype.getCryptoMintNft = function() {
  return /** @type{?proto.qis.product.v1.CryptoMintNFT} */ (
    jspb.Message.getWrapperField(this, proto.qis.product.v1.CryptoMintNFT, 25));
};


/**
 * @param {?proto.qis.product.v1.CryptoMintNFT|undefined} value
 * @return {!proto.qis.product.v1.Product} returns this
*/
proto.qis.product.v1.Product.prototype.setCryptoMintNft = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.qis.product.v1.Product.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.product.v1.Product} returns this
 */
proto.qis.product.v1.Product.prototype.clearCryptoMintNft = function() {
  return this.setCryptoMintNft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.product.v1.Product.prototype.hasCryptoMintNft = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional Side side = 10;
 * @return {!proto.qis.product.v1.Side}
 */
proto.qis.product.v1.Product.prototype.getSide = function() {
  return /** @type {!proto.qis.product.v1.Side} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.qis.product.v1.Side} value
 * @return {!proto.qis.product.v1.Product} returns this
 */
proto.qis.product.v1.Product.prototype.setSide = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional double slippage = 11;
 * @return {number}
 */
proto.qis.product.v1.Product.prototype.getSlippage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qis.product.v1.Product} returns this
 */
proto.qis.product.v1.Product.prototype.setSlippage = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double rebate = 12;
 * @return {number}
 */
proto.qis.product.v1.Product.prototype.getRebate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qis.product.v1.Product} returns this
 */
proto.qis.product.v1.Product.prototype.setRebate = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.product.v1.CryptoSwap.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.product.v1.CryptoSwap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.product.v1.CryptoSwap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.product.v1.CryptoSwap.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseCurrency: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quoteCurrency: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.product.v1.CryptoSwap}
 */
proto.qis.product.v1.CryptoSwap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.product.v1.CryptoSwap;
  return proto.qis.product.v1.CryptoSwap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.product.v1.CryptoSwap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.product.v1.CryptoSwap}
 */
proto.qis.product.v1.CryptoSwap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.qis.regional.v1.CryptoCode} */ (reader.readEnum());
      msg.setBaseCurrency(value);
      break;
    case 2:
      var value = /** @type {!proto.qis.regional.v1.CryptoCode} */ (reader.readEnum());
      msg.setQuoteCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.product.v1.CryptoSwap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.product.v1.CryptoSwap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.product.v1.CryptoSwap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.product.v1.CryptoSwap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQuoteCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional qis.regional.v1.CryptoCode base_currency = 1;
 * @return {!proto.qis.regional.v1.CryptoCode}
 */
proto.qis.product.v1.CryptoSwap.prototype.getBaseCurrency = function() {
  return /** @type {!proto.qis.regional.v1.CryptoCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.qis.regional.v1.CryptoCode} value
 * @return {!proto.qis.product.v1.CryptoSwap} returns this
 */
proto.qis.product.v1.CryptoSwap.prototype.setBaseCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional qis.regional.v1.CryptoCode quote_currency = 2;
 * @return {!proto.qis.regional.v1.CryptoCode}
 */
proto.qis.product.v1.CryptoSwap.prototype.getQuoteCurrency = function() {
  return /** @type {!proto.qis.regional.v1.CryptoCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.qis.regional.v1.CryptoCode} value
 * @return {!proto.qis.product.v1.CryptoSwap} returns this
 */
proto.qis.product.v1.CryptoSwap.prototype.setQuoteCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.product.v1.CryptoFiatSwap.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.product.v1.CryptoFiatSwap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.product.v1.CryptoFiatSwap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.product.v1.CryptoFiatSwap.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseCurrency: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quoteCurrency: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.product.v1.CryptoFiatSwap}
 */
proto.qis.product.v1.CryptoFiatSwap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.product.v1.CryptoFiatSwap;
  return proto.qis.product.v1.CryptoFiatSwap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.product.v1.CryptoFiatSwap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.product.v1.CryptoFiatSwap}
 */
proto.qis.product.v1.CryptoFiatSwap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.qis.regional.v1.CryptoCode} */ (reader.readEnum());
      msg.setBaseCurrency(value);
      break;
    case 2:
      var value = /** @type {!proto.qis.regional.v1.MoneyCode} */ (reader.readEnum());
      msg.setQuoteCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.product.v1.CryptoFiatSwap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.product.v1.CryptoFiatSwap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.product.v1.CryptoFiatSwap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.product.v1.CryptoFiatSwap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQuoteCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional qis.regional.v1.CryptoCode base_currency = 1;
 * @return {!proto.qis.regional.v1.CryptoCode}
 */
proto.qis.product.v1.CryptoFiatSwap.prototype.getBaseCurrency = function() {
  return /** @type {!proto.qis.regional.v1.CryptoCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.qis.regional.v1.CryptoCode} value
 * @return {!proto.qis.product.v1.CryptoFiatSwap} returns this
 */
proto.qis.product.v1.CryptoFiatSwap.prototype.setBaseCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional qis.regional.v1.MoneyCode quote_currency = 2;
 * @return {!proto.qis.regional.v1.MoneyCode}
 */
proto.qis.product.v1.CryptoFiatSwap.prototype.getQuoteCurrency = function() {
  return /** @type {!proto.qis.regional.v1.MoneyCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.qis.regional.v1.MoneyCode} value
 * @return {!proto.qis.product.v1.CryptoFiatSwap} returns this
 */
proto.qis.product.v1.CryptoFiatSwap.prototype.setQuoteCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.product.v1.FiatCrytoSwap.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.product.v1.FiatCrytoSwap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.product.v1.FiatCrytoSwap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.product.v1.FiatCrytoSwap.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseCurrency: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quoteCurrency: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.product.v1.FiatCrytoSwap}
 */
proto.qis.product.v1.FiatCrytoSwap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.product.v1.FiatCrytoSwap;
  return proto.qis.product.v1.FiatCrytoSwap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.product.v1.FiatCrytoSwap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.product.v1.FiatCrytoSwap}
 */
proto.qis.product.v1.FiatCrytoSwap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.qis.regional.v1.MoneyCode} */ (reader.readEnum());
      msg.setBaseCurrency(value);
      break;
    case 2:
      var value = /** @type {!proto.qis.regional.v1.CryptoCode} */ (reader.readEnum());
      msg.setQuoteCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.product.v1.FiatCrytoSwap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.product.v1.FiatCrytoSwap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.product.v1.FiatCrytoSwap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.product.v1.FiatCrytoSwap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQuoteCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional qis.regional.v1.MoneyCode base_currency = 1;
 * @return {!proto.qis.regional.v1.MoneyCode}
 */
proto.qis.product.v1.FiatCrytoSwap.prototype.getBaseCurrency = function() {
  return /** @type {!proto.qis.regional.v1.MoneyCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.qis.regional.v1.MoneyCode} value
 * @return {!proto.qis.product.v1.FiatCrytoSwap} returns this
 */
proto.qis.product.v1.FiatCrytoSwap.prototype.setBaseCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional qis.regional.v1.CryptoCode quote_currency = 2;
 * @return {!proto.qis.regional.v1.CryptoCode}
 */
proto.qis.product.v1.FiatCrytoSwap.prototype.getQuoteCurrency = function() {
  return /** @type {!proto.qis.regional.v1.CryptoCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.qis.regional.v1.CryptoCode} value
 * @return {!proto.qis.product.v1.FiatCrytoSwap} returns this
 */
proto.qis.product.v1.FiatCrytoSwap.prototype.setQuoteCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.product.v1.FiatMintNFT.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.product.v1.FiatMintNFT.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.product.v1.FiatMintNFT} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.product.v1.FiatMintNFT.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseCurrency: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nft: (f = msg.getNft()) && proto.qis.product.v1.NFT.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.product.v1.FiatMintNFT}
 */
proto.qis.product.v1.FiatMintNFT.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.product.v1.FiatMintNFT;
  return proto.qis.product.v1.FiatMintNFT.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.product.v1.FiatMintNFT} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.product.v1.FiatMintNFT}
 */
proto.qis.product.v1.FiatMintNFT.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.qis.regional.v1.MoneyCode} */ (reader.readEnum());
      msg.setBaseCurrency(value);
      break;
    case 2:
      var value = new proto.qis.product.v1.NFT;
      reader.readMessage(value,proto.qis.product.v1.NFT.deserializeBinaryFromReader);
      msg.setNft(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.product.v1.FiatMintNFT.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.product.v1.FiatMintNFT.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.product.v1.FiatMintNFT} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.product.v1.FiatMintNFT.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getNft();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qis.product.v1.NFT.serializeBinaryToWriter
    );
  }
};


/**
 * optional qis.regional.v1.MoneyCode base_currency = 1;
 * @return {!proto.qis.regional.v1.MoneyCode}
 */
proto.qis.product.v1.FiatMintNFT.prototype.getBaseCurrency = function() {
  return /** @type {!proto.qis.regional.v1.MoneyCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.qis.regional.v1.MoneyCode} value
 * @return {!proto.qis.product.v1.FiatMintNFT} returns this
 */
proto.qis.product.v1.FiatMintNFT.prototype.setBaseCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional NFT nft = 2;
 * @return {?proto.qis.product.v1.NFT}
 */
proto.qis.product.v1.FiatMintNFT.prototype.getNft = function() {
  return /** @type{?proto.qis.product.v1.NFT} */ (
    jspb.Message.getWrapperField(this, proto.qis.product.v1.NFT, 2));
};


/**
 * @param {?proto.qis.product.v1.NFT|undefined} value
 * @return {!proto.qis.product.v1.FiatMintNFT} returns this
*/
proto.qis.product.v1.FiatMintNFT.prototype.setNft = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.product.v1.FiatMintNFT} returns this
 */
proto.qis.product.v1.FiatMintNFT.prototype.clearNft = function() {
  return this.setNft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.product.v1.FiatMintNFT.prototype.hasNft = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.product.v1.CryptoMintNFT.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.product.v1.CryptoMintNFT.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.product.v1.CryptoMintNFT} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.product.v1.CryptoMintNFT.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseCurrency: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nft: (f = msg.getNft()) && proto.qis.product.v1.NFT.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.product.v1.CryptoMintNFT}
 */
proto.qis.product.v1.CryptoMintNFT.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.product.v1.CryptoMintNFT;
  return proto.qis.product.v1.CryptoMintNFT.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.product.v1.CryptoMintNFT} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.product.v1.CryptoMintNFT}
 */
proto.qis.product.v1.CryptoMintNFT.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.qis.regional.v1.CryptoCode} */ (reader.readEnum());
      msg.setBaseCurrency(value);
      break;
    case 2:
      var value = new proto.qis.product.v1.NFT;
      reader.readMessage(value,proto.qis.product.v1.NFT.deserializeBinaryFromReader);
      msg.setNft(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.product.v1.CryptoMintNFT.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.product.v1.CryptoMintNFT.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.product.v1.CryptoMintNFT} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.product.v1.CryptoMintNFT.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getNft();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.qis.product.v1.NFT.serializeBinaryToWriter
    );
  }
};


/**
 * optional qis.regional.v1.CryptoCode base_currency = 1;
 * @return {!proto.qis.regional.v1.CryptoCode}
 */
proto.qis.product.v1.CryptoMintNFT.prototype.getBaseCurrency = function() {
  return /** @type {!proto.qis.regional.v1.CryptoCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.qis.regional.v1.CryptoCode} value
 * @return {!proto.qis.product.v1.CryptoMintNFT} returns this
 */
proto.qis.product.v1.CryptoMintNFT.prototype.setBaseCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional NFT nft = 2;
 * @return {?proto.qis.product.v1.NFT}
 */
proto.qis.product.v1.CryptoMintNFT.prototype.getNft = function() {
  return /** @type{?proto.qis.product.v1.NFT} */ (
    jspb.Message.getWrapperField(this, proto.qis.product.v1.NFT, 2));
};


/**
 * @param {?proto.qis.product.v1.NFT|undefined} value
 * @return {!proto.qis.product.v1.CryptoMintNFT} returns this
*/
proto.qis.product.v1.CryptoMintNFT.prototype.setNft = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.product.v1.CryptoMintNFT} returns this
 */
proto.qis.product.v1.CryptoMintNFT.prototype.clearNft = function() {
  return this.setNft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.product.v1.CryptoMintNFT.prototype.hasNft = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.product.v1.NFT.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.product.v1.NFT.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.product.v1.NFT} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.product.v1.NFT.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contractAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    blockchainNetwork: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.product.v1.NFT}
 */
proto.qis.product.v1.NFT.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.product.v1.NFT;
  return proto.qis.product.v1.NFT.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.product.v1.NFT} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.product.v1.NFT}
 */
proto.qis.product.v1.NFT.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractAddress(value);
      break;
    case 3:
      var value = /** @type {!proto.qis.transaction.v1.Network} */ (reader.readEnum());
      msg.setBlockchainNetwork(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.product.v1.NFT.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.product.v1.NFT.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.product.v1.NFT} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.product.v1.NFT.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContractAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBlockchainNetwork();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.qis.product.v1.NFT.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.product.v1.NFT} returns this
 */
proto.qis.product.v1.NFT.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string contract_address = 2;
 * @return {string}
 */
proto.qis.product.v1.NFT.prototype.getContractAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.product.v1.NFT} returns this
 */
proto.qis.product.v1.NFT.prototype.setContractAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional qis.transaction.v1.Network blockchain_network = 3;
 * @return {!proto.qis.transaction.v1.Network}
 */
proto.qis.product.v1.NFT.prototype.getBlockchainNetwork = function() {
  return /** @type {!proto.qis.transaction.v1.Network} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.qis.transaction.v1.Network} value
 * @return {!proto.qis.product.v1.NFT} returns this
 */
proto.qis.product.v1.NFT.prototype.setBlockchainNetwork = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.qis.product.v1.Side = {
  UNSPECIFIED: 0,
  BUY: 1,
  SELL: -1
};

goog.object.extend(exports, proto.qis.product.v1);
