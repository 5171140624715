<template>
    <div id="app">
      <go-top bg-color="#172b4d" :bottom=25 :right=25 alt="top" :max-width=0></go-top>
        <router-view name="header"></router-view>
        <main>
            <fade-transition origin="center" mode="out-in" :duration="250">
                <router-view/>
            </fade-transition>
        </main>
        <router-view name="footer"></router-view>
    </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import GoTop from '@inotom/vue-go-top';

export default {
  components: {
    FadeTransition,
    GoTop
  }
};
</script>
