// source: qis/transaction/v1/transaction.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var qis_regional_v1_regional_pb = require('../../../qis/regional/v1/regional_pb.js');
goog.object.extend(proto, qis_regional_v1_regional_pb);
var qis_transaction_v1_payment_card_pb = require('../../../qis/transaction/v1/payment_card_pb.js');
goog.object.extend(proto, qis_transaction_v1_payment_card_pb);
var qis_transaction_v1_crypto_pb = require('../../../qis/transaction/v1/crypto_pb.js');
goog.object.extend(proto, qis_transaction_v1_crypto_pb);
var qis_transaction_v1_pix_pb = require('../../../qis/transaction/v1/pix_pb.js');
goog.object.extend(proto, qis_transaction_v1_pix_pb);
goog.exportSymbol('proto.qis.transaction.v1.CounterpartyTransaction', null, global);
goog.exportSymbol('proto.qis.transaction.v1.CounterpartyTransaction.TransacionTypeCase', null, global);
goog.exportSymbol('proto.qis.transaction.v1.Device', null, global);
goog.exportSymbol('proto.qis.transaction.v1.ExecutionService', null, global);
goog.exportSymbol('proto.qis.transaction.v1.Transaction', null, global);
goog.exportSymbol('proto.qis.transaction.v1.TransactionStatus', null, global);
goog.exportSymbol('proto.qis.transaction.v1.TransactionType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.transaction.v1.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.transaction.v1.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.transaction.v1.Transaction.displayName = 'proto.qis.transaction.v1.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.transaction.v1.Device = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.transaction.v1.Device, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.transaction.v1.Device.displayName = 'proto.qis.transaction.v1.Device';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.transaction.v1.CounterpartyTransaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.qis.transaction.v1.CounterpartyTransaction.oneofGroups_);
};
goog.inherits(proto.qis.transaction.v1.CounterpartyTransaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.transaction.v1.CounterpartyTransaction.displayName = 'proto.qis.transaction.v1.CounterpartyTransaction';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.transaction.v1.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.transaction.v1.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.transaction.v1.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    money: jspb.Message.getFieldWithDefault(msg, 5, 0),
    reason: jspb.Message.getFieldWithDefault(msg, 6, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    counterpartyTransaction: (f = msg.getCounterpartyTransaction()) && proto.qis.transaction.v1.CounterpartyTransaction.toObject(includeInstance, f),
    device: (f = msg.getDevice()) && proto.qis.transaction.v1.Device.toObject(includeInstance, f),
    executionService: jspb.Message.getFieldWithDefault(msg, 10, 0),
    externalServiceId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.transaction.v1.Transaction}
 */
proto.qis.transaction.v1.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.transaction.v1.Transaction;
  return proto.qis.transaction.v1.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.transaction.v1.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.transaction.v1.Transaction}
 */
proto.qis.transaction.v1.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.qis.transaction.v1.TransactionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {!proto.qis.transaction.v1.TransactionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {!proto.qis.regional.v1.MoneyCode} */ (reader.readEnum());
      msg.setMoney(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyId(value);
      break;
    case 8:
      var value = new proto.qis.transaction.v1.CounterpartyTransaction;
      reader.readMessage(value,proto.qis.transaction.v1.CounterpartyTransaction.deserializeBinaryFromReader);
      msg.setCounterpartyTransaction(value);
      break;
    case 9:
      var value = new proto.qis.transaction.v1.Device;
      reader.readMessage(value,proto.qis.transaction.v1.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 10:
      var value = /** @type {!proto.qis.transaction.v1.ExecutionService} */ (reader.readEnum());
      msg.setExecutionService(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalServiceId(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.transaction.v1.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.transaction.v1.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.transaction.v1.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getMoney();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCompanyId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCounterpartyTransaction();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.qis.transaction.v1.CounterpartyTransaction.serializeBinaryToWriter
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.qis.transaction.v1.Device.serializeBinaryToWriter
    );
  }
  f = message.getExecutionService();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getExternalServiceId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.qis.transaction.v1.Transaction.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.Transaction} returns this
 */
proto.qis.transaction.v1.Transaction.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TransactionStatus status = 2;
 * @return {!proto.qis.transaction.v1.TransactionStatus}
 */
proto.qis.transaction.v1.Transaction.prototype.getStatus = function() {
  return /** @type {!proto.qis.transaction.v1.TransactionStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.qis.transaction.v1.TransactionStatus} value
 * @return {!proto.qis.transaction.v1.Transaction} returns this
 */
proto.qis.transaction.v1.Transaction.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional TransactionType type = 3;
 * @return {!proto.qis.transaction.v1.TransactionType}
 */
proto.qis.transaction.v1.Transaction.prototype.getType = function() {
  return /** @type {!proto.qis.transaction.v1.TransactionType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.qis.transaction.v1.TransactionType} value
 * @return {!proto.qis.transaction.v1.Transaction} returns this
 */
proto.qis.transaction.v1.Transaction.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional double amount = 4;
 * @return {number}
 */
proto.qis.transaction.v1.Transaction.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qis.transaction.v1.Transaction} returns this
 */
proto.qis.transaction.v1.Transaction.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional qis.regional.v1.MoneyCode money = 5;
 * @return {!proto.qis.regional.v1.MoneyCode}
 */
proto.qis.transaction.v1.Transaction.prototype.getMoney = function() {
  return /** @type {!proto.qis.regional.v1.MoneyCode} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.qis.regional.v1.MoneyCode} value
 * @return {!proto.qis.transaction.v1.Transaction} returns this
 */
proto.qis.transaction.v1.Transaction.prototype.setMoney = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string reason = 6;
 * @return {string}
 */
proto.qis.transaction.v1.Transaction.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.Transaction} returns this
 */
proto.qis.transaction.v1.Transaction.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string company_id = 7;
 * @return {string}
 */
proto.qis.transaction.v1.Transaction.prototype.getCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.Transaction} returns this
 */
proto.qis.transaction.v1.Transaction.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional CounterpartyTransaction counterparty_transaction = 8;
 * @return {?proto.qis.transaction.v1.CounterpartyTransaction}
 */
proto.qis.transaction.v1.Transaction.prototype.getCounterpartyTransaction = function() {
  return /** @type{?proto.qis.transaction.v1.CounterpartyTransaction} */ (
    jspb.Message.getWrapperField(this, proto.qis.transaction.v1.CounterpartyTransaction, 8));
};


/**
 * @param {?proto.qis.transaction.v1.CounterpartyTransaction|undefined} value
 * @return {!proto.qis.transaction.v1.Transaction} returns this
*/
proto.qis.transaction.v1.Transaction.prototype.setCounterpartyTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.transaction.v1.Transaction} returns this
 */
proto.qis.transaction.v1.Transaction.prototype.clearCounterpartyTransaction = function() {
  return this.setCounterpartyTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.transaction.v1.Transaction.prototype.hasCounterpartyTransaction = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Device device = 9;
 * @return {?proto.qis.transaction.v1.Device}
 */
proto.qis.transaction.v1.Transaction.prototype.getDevice = function() {
  return /** @type{?proto.qis.transaction.v1.Device} */ (
    jspb.Message.getWrapperField(this, proto.qis.transaction.v1.Device, 9));
};


/**
 * @param {?proto.qis.transaction.v1.Device|undefined} value
 * @return {!proto.qis.transaction.v1.Transaction} returns this
*/
proto.qis.transaction.v1.Transaction.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.transaction.v1.Transaction} returns this
 */
proto.qis.transaction.v1.Transaction.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.transaction.v1.Transaction.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ExecutionService execution_service = 10;
 * @return {!proto.qis.transaction.v1.ExecutionService}
 */
proto.qis.transaction.v1.Transaction.prototype.getExecutionService = function() {
  return /** @type {!proto.qis.transaction.v1.ExecutionService} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.qis.transaction.v1.ExecutionService} value
 * @return {!proto.qis.transaction.v1.Transaction} returns this
 */
proto.qis.transaction.v1.Transaction.prototype.setExecutionService = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string external_service_id = 11;
 * @return {string}
 */
proto.qis.transaction.v1.Transaction.prototype.getExternalServiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.Transaction} returns this
 */
proto.qis.transaction.v1.Transaction.prototype.setExternalServiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp create_time = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.transaction.v1.Transaction.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.transaction.v1.Transaction} returns this
*/
proto.qis.transaction.v1.Transaction.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.transaction.v1.Transaction} returns this
 */
proto.qis.transaction.v1.Transaction.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.transaction.v1.Transaction.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.transaction.v1.Device.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.transaction.v1.Device.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.transaction.v1.Device} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.Device.toObject = function(includeInstance, msg) {
  var f, obj = {
    ipAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    port: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userAgent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    geolocation: jspb.Message.getFieldWithDefault(msg, 4, ""),
    timeZone: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.transaction.v1.Device}
 */
proto.qis.transaction.v1.Device.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.transaction.v1.Device;
  return proto.qis.transaction.v1.Device.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.transaction.v1.Device} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.transaction.v1.Device}
 */
proto.qis.transaction.v1.Device.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPort(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserAgent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGeolocation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeZone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.transaction.v1.Device.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.transaction.v1.Device.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.transaction.v1.Device} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.Device.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPort();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserAgent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGeolocation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTimeZone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string ip_address = 1;
 * @return {string}
 */
proto.qis.transaction.v1.Device.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.Device} returns this
 */
proto.qis.transaction.v1.Device.prototype.setIpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string port = 2;
 * @return {string}
 */
proto.qis.transaction.v1.Device.prototype.getPort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.Device} returns this
 */
proto.qis.transaction.v1.Device.prototype.setPort = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_agent = 3;
 * @return {string}
 */
proto.qis.transaction.v1.Device.prototype.getUserAgent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.Device} returns this
 */
proto.qis.transaction.v1.Device.prototype.setUserAgent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string geolocation = 4;
 * @return {string}
 */
proto.qis.transaction.v1.Device.prototype.getGeolocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.Device} returns this
 */
proto.qis.transaction.v1.Device.prototype.setGeolocation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string time_zone = 5;
 * @return {string}
 */
proto.qis.transaction.v1.Device.prototype.getTimeZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.Device} returns this
 */
proto.qis.transaction.v1.Device.prototype.setTimeZone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.qis.transaction.v1.CounterpartyTransaction.oneofGroups_ = [[10,11,12]];

/**
 * @enum {number}
 */
proto.qis.transaction.v1.CounterpartyTransaction.TransacionTypeCase = {
  TRANSACION_TYPE_NOT_SET: 0,
  CRYPTO_TRANSACTION: 10,
  CREDIT_CARD_TRANSACTION: 11,
  PIX: 12
};

/**
 * @return {proto.qis.transaction.v1.CounterpartyTransaction.TransacionTypeCase}
 */
proto.qis.transaction.v1.CounterpartyTransaction.prototype.getTransacionTypeCase = function() {
  return /** @type {proto.qis.transaction.v1.CounterpartyTransaction.TransacionTypeCase} */(jspb.Message.computeOneofCase(this, proto.qis.transaction.v1.CounterpartyTransaction.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.transaction.v1.CounterpartyTransaction.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.transaction.v1.CounterpartyTransaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.transaction.v1.CounterpartyTransaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.CounterpartyTransaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromIdentity: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cryptoTransaction: (f = msg.getCryptoTransaction()) && qis_transaction_v1_crypto_pb.CryptoTransaction.toObject(includeInstance, f),
    creditCardTransaction: (f = msg.getCreditCardTransaction()) && qis_transaction_v1_payment_card_pb.CreditCardTransaction.toObject(includeInstance, f),
    pix: (f = msg.getPix()) && qis_transaction_v1_pix_pb.PIX.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.transaction.v1.CounterpartyTransaction}
 */
proto.qis.transaction.v1.CounterpartyTransaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.transaction.v1.CounterpartyTransaction;
  return proto.qis.transaction.v1.CounterpartyTransaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.transaction.v1.CounterpartyTransaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.transaction.v1.CounterpartyTransaction}
 */
proto.qis.transaction.v1.CounterpartyTransaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromIdentity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 10:
      var value = new qis_transaction_v1_crypto_pb.CryptoTransaction;
      reader.readMessage(value,qis_transaction_v1_crypto_pb.CryptoTransaction.deserializeBinaryFromReader);
      msg.setCryptoTransaction(value);
      break;
    case 11:
      var value = new qis_transaction_v1_payment_card_pb.CreditCardTransaction;
      reader.readMessage(value,qis_transaction_v1_payment_card_pb.CreditCardTransaction.deserializeBinaryFromReader);
      msg.setCreditCardTransaction(value);
      break;
    case 12:
      var value = new qis_transaction_v1_pix_pb.PIX;
      reader.readMessage(value,qis_transaction_v1_pix_pb.PIX.deserializeBinaryFromReader);
      msg.setPix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.transaction.v1.CounterpartyTransaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.transaction.v1.CounterpartyTransaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.transaction.v1.CounterpartyTransaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.CounterpartyTransaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromIdentity();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCryptoTransaction();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      qis_transaction_v1_crypto_pb.CryptoTransaction.serializeBinaryToWriter
    );
  }
  f = message.getCreditCardTransaction();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      qis_transaction_v1_payment_card_pb.CreditCardTransaction.serializeBinaryToWriter
    );
  }
  f = message.getPix();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      qis_transaction_v1_pix_pb.PIX.serializeBinaryToWriter
    );
  }
};


/**
 * optional string from_identity = 1;
 * @return {string}
 */
proto.qis.transaction.v1.CounterpartyTransaction.prototype.getFromIdentity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.CounterpartyTransaction} returns this
 */
proto.qis.transaction.v1.CounterpartyTransaction.prototype.setFromIdentity = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.qis.transaction.v1.CounterpartyTransaction.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.CounterpartyTransaction} returns this
 */
proto.qis.transaction.v1.CounterpartyTransaction.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CryptoTransaction crypto_transaction = 10;
 * @return {?proto.qis.transaction.v1.CryptoTransaction}
 */
proto.qis.transaction.v1.CounterpartyTransaction.prototype.getCryptoTransaction = function() {
  return /** @type{?proto.qis.transaction.v1.CryptoTransaction} */ (
    jspb.Message.getWrapperField(this, qis_transaction_v1_crypto_pb.CryptoTransaction, 10));
};


/**
 * @param {?proto.qis.transaction.v1.CryptoTransaction|undefined} value
 * @return {!proto.qis.transaction.v1.CounterpartyTransaction} returns this
*/
proto.qis.transaction.v1.CounterpartyTransaction.prototype.setCryptoTransaction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.qis.transaction.v1.CounterpartyTransaction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.transaction.v1.CounterpartyTransaction} returns this
 */
proto.qis.transaction.v1.CounterpartyTransaction.prototype.clearCryptoTransaction = function() {
  return this.setCryptoTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.transaction.v1.CounterpartyTransaction.prototype.hasCryptoTransaction = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional CreditCardTransaction credit_card_transaction = 11;
 * @return {?proto.qis.transaction.v1.CreditCardTransaction}
 */
proto.qis.transaction.v1.CounterpartyTransaction.prototype.getCreditCardTransaction = function() {
  return /** @type{?proto.qis.transaction.v1.CreditCardTransaction} */ (
    jspb.Message.getWrapperField(this, qis_transaction_v1_payment_card_pb.CreditCardTransaction, 11));
};


/**
 * @param {?proto.qis.transaction.v1.CreditCardTransaction|undefined} value
 * @return {!proto.qis.transaction.v1.CounterpartyTransaction} returns this
*/
proto.qis.transaction.v1.CounterpartyTransaction.prototype.setCreditCardTransaction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.qis.transaction.v1.CounterpartyTransaction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.transaction.v1.CounterpartyTransaction} returns this
 */
proto.qis.transaction.v1.CounterpartyTransaction.prototype.clearCreditCardTransaction = function() {
  return this.setCreditCardTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.transaction.v1.CounterpartyTransaction.prototype.hasCreditCardTransaction = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PIX pix = 12;
 * @return {?proto.qis.transaction.v1.PIX}
 */
proto.qis.transaction.v1.CounterpartyTransaction.prototype.getPix = function() {
  return /** @type{?proto.qis.transaction.v1.PIX} */ (
    jspb.Message.getWrapperField(this, qis_transaction_v1_pix_pb.PIX, 12));
};


/**
 * @param {?proto.qis.transaction.v1.PIX|undefined} value
 * @return {!proto.qis.transaction.v1.CounterpartyTransaction} returns this
*/
proto.qis.transaction.v1.CounterpartyTransaction.prototype.setPix = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.qis.transaction.v1.CounterpartyTransaction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.transaction.v1.CounterpartyTransaction} returns this
 */
proto.qis.transaction.v1.CounterpartyTransaction.prototype.clearPix = function() {
  return this.setPix(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.transaction.v1.CounterpartyTransaction.prototype.hasPix = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * @enum {number}
 */
proto.qis.transaction.v1.TransactionStatus = {
  TRANSACTION_STATUS_NOT_SPECIFIED: 0,
  TRANSACTION_REJECT: 1,
  TRANSACTION_ACCEPTED: 2,
  TRANSACTION_FULFILLED: 3,
  TRANSACTION_CANCELLED: 4
};

/**
 * @enum {number}
 */
proto.qis.transaction.v1.TransactionType = {
  TRANSACTION_TYPE_NOT_SPECIFIED: 0,
  DEPOSIT: 1,
  WITHDRAW: 2
};

/**
 * @enum {number}
 */
proto.qis.transaction.v1.ExecutionService = {
  TRANSACTION_SERVICE_NOT_SPECIFIED: 0,
  ORDER_SERVICE: 1
};

goog.object.extend(exports, proto.qis.transaction.v1);
