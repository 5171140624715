import Vue from "vue";
import VueCurrencyFilter from 'vue-currency-filter';
import numeral from "numeral";

Vue.filter('percentage', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    return `${numeral(value).format('0.00 %')}`;
})

Vue.filter('BRL', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    return `R$ ${numeral(value).format('0,0.00')}`;
})

Vue.use(VueCurrencyFilter, {
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true,
    avoidEmptyDecimals: undefined,
})