import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Inovaquant from "./plugins/inovaquant-kit";
import "./filters/filters";
import EventBus from "./event-bus";

Vue.config.productionTip = false;
Vue.use(Inovaquant);
new Vue({
  el: '#app',
  router,
  data: {
    bus: EventBus // Here we bind our event bus to our $root Vue model.
  },
  render: (h) => h(App)
})