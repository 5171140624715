import "@/assets/vendor/nucleo/css/nucleo.css";
import "@/assets/vendor/font-awesome/css/font-awesome.css";
import "@/assets/scss/inovaquant.scss";
import 'sweetalert2/dist/sweetalert2.min.css';
import "vue-toastification/dist/index.css";
import globalComponents from "./globalComponents";
import globalDirectives from "./globalDirectives";
import VueLazyload from "vue-lazyload";
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueSweetalert2 from 'vue-sweetalert2';
import VueTheMask from 'vue-the-mask';
import Vuelidate from 'vuelidate';
import Toast from "vue-toastification";


export default {
  install(Vue) {
    Vue.use(globalComponents);
    Vue.use(globalDirectives);
    Vue.use(VueLazyload);
    Vue.use(VueSweetalert2);
    Vue.use(Vuelidate);
    Vue.use(VueTheMask);
    Vue.use(VueReCaptcha,
      { siteKey: "6LeFcoYbAAAAAJ_O4fbZzndNFaI5pL43edk0Fxg_",
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true
    }});
    Vue.use(Toast, {
      transition: "Vue-Toastification__bounce",
      maxToasts: 2,
      newestOnTop: true
    });
  }
};
