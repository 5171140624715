export class AuthorizationInterceptor {
    intercept = function(request, invoker) {
        // Update the request message before the RPC.
        //const reqMsg = request.getRequestMessage();
        //reqMsg.setMessage('[Intercept request]' + reqMsg.getMessage());
        request.getMetadata()['Authorization'] = this.token();
        // After the RPC returns successfully, update the response.
        return invoker(request).then((response) => {
          // You can also do something with response metadata here.
          //console.log(response.getMetadata());
      
          // Update the response message.
          //const responseMsg = response.getResponseMessage();
          //responseMsg.setMessage('[Intercept response]' + responseMsg.getMessage());
      
          return response;
        });
    };
    token() {
      let retorno = null;
      let jwt = localStorage.getItem('user_token');
      if (jwt) {
        retorno = `Bearer ${jwt}`;
      }
      return retorno
    }
}

