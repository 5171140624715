/**
 * @fileoverview gRPC-Web generated client stub for qis.marketdata.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_api_annotations_pb = require('../../../google/api/annotations_pb.js')

var qis_instrument_v1_instrument_pb = require('../../../qis/instrument/v1/instrument_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var qis_parties_v1_party_pb = require('../../../qis/parties/v1/party_pb.js')

var qis_parties_v1_counterparty_pb = require('../../../qis/parties/v1/counterparty_pb.js')

var qis_exchange_v1_exchange_pb = require('../../../qis/exchange/v1/exchange_pb.js')
const proto = {};
proto.qis = {};
proto.qis.marketdata = {};
proto.qis.marketdata.v1 = require('./marketdata_api_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.qis.marketdata.v1.MarketDataServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.qis.marketdata.v1.MarketDataServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qis.marketdata.v1.InstrumentRequest,
 *   !proto.qis.marketdata.v1.InstrumentSnapshotResponse>}
 */
const methodDescriptor_MarketDataService_Snapshot = new grpc.web.MethodDescriptor(
  '/qis.marketdata.v1.MarketDataService/Snapshot',
  grpc.web.MethodType.UNARY,
  proto.qis.marketdata.v1.InstrumentRequest,
  proto.qis.marketdata.v1.InstrumentSnapshotResponse,
  /**
   * @param {!proto.qis.marketdata.v1.InstrumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.marketdata.v1.InstrumentSnapshotResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.qis.marketdata.v1.InstrumentRequest,
 *   !proto.qis.marketdata.v1.InstrumentSnapshotResponse>}
 */
const methodInfo_MarketDataService_Snapshot = new grpc.web.AbstractClientBase.MethodInfo(
  proto.qis.marketdata.v1.InstrumentSnapshotResponse,
  /**
   * @param {!proto.qis.marketdata.v1.InstrumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.marketdata.v1.InstrumentSnapshotResponse.deserializeBinary
);


/**
 * @param {!proto.qis.marketdata.v1.InstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.qis.marketdata.v1.InstrumentSnapshotResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qis.marketdata.v1.InstrumentSnapshotResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qis.marketdata.v1.MarketDataServiceClient.prototype.snapshot =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qis.marketdata.v1.MarketDataService/Snapshot',
      request,
      metadata || {},
      methodDescriptor_MarketDataService_Snapshot,
      callback);
};


/**
 * @param {!proto.qis.marketdata.v1.InstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qis.marketdata.v1.InstrumentSnapshotResponse>}
 *     Promise that resolves to the response
 */
proto.qis.marketdata.v1.MarketDataServicePromiseClient.prototype.snapshot =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qis.marketdata.v1.MarketDataService/Snapshot',
      request,
      metadata || {},
      methodDescriptor_MarketDataService_Snapshot);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qis.marketdata.v1.InstrumentRequest,
 *   !proto.qis.marketdata.v1.InstrumentResponseRealtime>}
 */
const methodDescriptor_MarketDataService_WatchRealtime = new grpc.web.MethodDescriptor(
  '/qis.marketdata.v1.MarketDataService/WatchRealtime',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.qis.marketdata.v1.InstrumentRequest,
  proto.qis.marketdata.v1.InstrumentResponseRealtime,
  /**
   * @param {!proto.qis.marketdata.v1.InstrumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.marketdata.v1.InstrumentResponseRealtime.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.qis.marketdata.v1.InstrumentRequest,
 *   !proto.qis.marketdata.v1.InstrumentResponseRealtime>}
 */
const methodInfo_MarketDataService_WatchRealtime = new grpc.web.AbstractClientBase.MethodInfo(
  proto.qis.marketdata.v1.InstrumentResponseRealtime,
  /**
   * @param {!proto.qis.marketdata.v1.InstrumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.marketdata.v1.InstrumentResponseRealtime.deserializeBinary
);


/**
 * @param {!proto.qis.marketdata.v1.InstrumentRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.qis.marketdata.v1.InstrumentResponseRealtime>}
 *     The XHR Node Readable Stream
 */
proto.qis.marketdata.v1.MarketDataServiceClient.prototype.watchRealtime =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/qis.marketdata.v1.MarketDataService/WatchRealtime',
      request,
      metadata || {},
      methodDescriptor_MarketDataService_WatchRealtime);
};


/**
 * @param {!proto.qis.marketdata.v1.InstrumentRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.qis.marketdata.v1.InstrumentResponseRealtime>}
 *     The XHR Node Readable Stream
 */
proto.qis.marketdata.v1.MarketDataServicePromiseClient.prototype.watchRealtime =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/qis.marketdata.v1.MarketDataService/WatchRealtime',
      request,
      metadata || {},
      methodDescriptor_MarketDataService_WatchRealtime);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest,
 *   !proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse>}
 */
const methodDescriptor_MarketDataService_LastPriceWallet = new grpc.web.MethodDescriptor(
  '/qis.marketdata.v1.MarketDataService/LastPriceWallet',
  grpc.web.MethodType.UNARY,
  proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest,
  proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse,
  /**
   * @param {!proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest,
 *   !proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse>}
 */
const methodInfo_MarketDataService_LastPriceWallet = new grpc.web.AbstractClientBase.MethodInfo(
  proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse,
  /**
   * @param {!proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse.deserializeBinary
);


/**
 * @param {!proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qis.marketdata.v1.MarketDataServiceClient.prototype.lastPriceWallet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qis.marketdata.v1.MarketDataService/LastPriceWallet',
      request,
      metadata || {},
      methodDescriptor_MarketDataService_LastPriceWallet,
      callback);
};


/**
 * @param {!proto.qis.marketdata.v1.InstrumentLastPriceWalletRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qis.marketdata.v1.InstrumentLastPriceWalletResponse>}
 *     Promise that resolves to the response
 */
proto.qis.marketdata.v1.MarketDataServicePromiseClient.prototype.lastPriceWallet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qis.marketdata.v1.MarketDataService/LastPriceWallet',
      request,
      metadata || {},
      methodDescriptor_MarketDataService_LastPriceWallet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice,
 *   !proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse>}
 */
const methodDescriptor_MarketDataService_HistoricalPriceBySymbol = new grpc.web.MethodDescriptor(
  '/qis.marketdata.v1.MarketDataService/HistoricalPriceBySymbol',
  grpc.web.MethodType.UNARY,
  proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice,
  proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse,
  /**
   * @param {!proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice,
 *   !proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse>}
 */
const methodInfo_MarketDataService_HistoricalPriceBySymbol = new grpc.web.AbstractClientBase.MethodInfo(
  proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse,
  /**
   * @param {!proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse.deserializeBinary
);


/**
 * @param {!proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qis.marketdata.v1.MarketDataServiceClient.prototype.historicalPriceBySymbol =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qis.marketdata.v1.MarketDataService/HistoricalPriceBySymbol',
      request,
      metadata || {},
      methodDescriptor_MarketDataService_HistoricalPriceBySymbol,
      callback);
};


/**
 * @param {!proto.qis.marketdata.v1.InstrumentRequestHistoricalPrice} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qis.marketdata.v1.InstrumentHistoricalPriceResponse>}
 *     Promise that resolves to the response
 */
proto.qis.marketdata.v1.MarketDataServicePromiseClient.prototype.historicalPriceBySymbol =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qis.marketdata.v1.MarketDataService/HistoricalPriceBySymbol',
      request,
      metadata || {},
      methodDescriptor_MarketDataService_HistoricalPriceBySymbol);
};


module.exports = proto.qis.marketdata.v1;

